import React, { useState } from 'react';
import "./landing.css";
import logo from "../logo.svg";

const LandingPage = () => {
    const [isVisible, setIsVisible] = useState(true);
    const handleClose = () => {
        setIsVisible(false);
        console.log("CLOSE ME");
    };

  return (
    <div className={`${!isVisible && "remove-me"} App-header landing-fixed`}>
        <img src={logo} className="App-logo" alt="logo" />
        <h3>Check my balance</h3>
        <div className='banner'>
            <div className='main-content'>
                <h3>
                    CHECK YOUR BALANCE <br/>
                    USING THE PAYZE WALLET.
                </h3>
                <p className='banner-small'>Download & install the PayZe Wallet</p>
                <p className='banner-small-2'>All the management tools and extra<br/>security to manage your card account.</p>
                <div className='store-container'>
                <a target='_blank' href='https://play.google.com/store/apps/details?id=com.cn.tranxactor&hl=en'><img src='/images/googlestore.png' alt='Download PayZe Wallet for Android' /></a>
                <a target='_blank' href='https://apps.apple.com/nz/app/payze-wallet-nz/id1641417257'><img src='/images/applestore.png' alt='Download PayZe Wallet for iOS' /></a>
            </div>
            </div>
            <div className='phone-container'>
                <img src='/images/phone.png' alt='' />
            </div>
        </div>
        <p className='small-text-2' onClick={handleClose}>Continue to Balance Check Page</p>
    </div>
  )
}

export default LandingPage;
