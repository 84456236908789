import React, { useState } from 'react';
import "./banner.css";

const Banner = () => {
    const [isVisible, setIsVisible] = useState(true);
    const handleClose = () => {
        setIsVisible(false);
    };

  return (
    <div className='banner'>
        <div className='main-content'>
            <h3>
                ALL YOUR CARDS<br/>
                IN ONE PLACE.
            </h3>
            <p className='banner-small'>Download & install the PayZe Wallet</p>
            <p className='banner-small-2'>All the management tools and extra<br/>security to manage your card account.</p>
            <div className='store-container'>
                <a target='_blank' href='https://play.google.com/store/apps/details?id=com.cn.tranxactor&hl=en'><img src='/images/googlestore.png' alt='Download PayZe Wallet for Android' /></a>
                <a target='_blank' href='https://apps.apple.com/nz/app/payze-wallet-nz/id1641417257'><img src='/images/applestore.png' alt='Download PayZe Wallet for iOS' /></a>
            </div>
        </div>
        <div className='phone-container'>
            <img src='/images/phone2.png' alt='' />
        </div>
    </div>
  )
}

export default Banner;
