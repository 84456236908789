import logo from '../logo.svg';
import "./balance.css";

function Balance(currentBalance) {
  return (
      <div className="App-header balance-container">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          <div className="balance-wrapper">
            <p>Your current balance:</p>
            <h1>${currentBalance}</h1>
          </div>
          <button className="lime-button">Logout</button>
        </div>
      </div>
  );
}

export default Balance;
