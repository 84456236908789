const convertDate = (date) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  let year = date.slice(0,4);
  let month = date.slice(4,6);
  let day = date.slice(6,8);
  return `${day} ${months[parseInt(month)-1]} ${year}`
}

function TransactionList(props) {
  return (
    <div className='transaction-container'>
      <div className='transaction-left'>
        <p className='main'>{props.shopName}</p>
        <p className='sub'>{props.desc}</p>
      </div>
      <div className='transaction-right'>
        <p className='main'>{props.amount} {props.currency}</p>
        <p className='sub right-align'>{convertDate(props.date)}</p>
      </div>
    </div>
  );

}

export default TransactionList;
