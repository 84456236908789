import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN_URL;

const cancelSources = [];

axios.interceptors.request.use(
  (config) => {
    const cfg = config;
    const cs = axios.CancelToken.source();
    cancelSources.push(cs);
    cfg.cancelToken = cs.token;
    return cfg;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response.data)
);

const cancelAll = () => {
  cancelSources.forEach((source) => source.cancel("Request cancelled"));
};

const { get, post, put, patch, request, isCancel } = axios;

const httpRequest = { get, post, put, patch, cancelAll, isCancel, request };

export default httpRequest;
