import logo from './logo.svg';
import { useEffect, useState, useRef } from 'react';
import Reaptcha from 'reaptcha';
import MaskedInput from 'react-text-mask'
import { httpRequest } from './utils/index';
import TransactionList from './components/TransactionList';
import Banner from './components/banner';
// import SearchIcon from './images/searchicon.svg';
import LandingPage from "./components/landing";

import './App.css';
import './transaction.css';


function App() {
  const [showBalance, setShowBalance] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [exp, setExp] = useState("");
  const [cvv2, setCvv2] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [isLoading, setLoading] = useState(false);
  //RECAPTCHA STATE
  const [recaptchaCheck, setRecaptchaCheck] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  // TRANSACTIONS
  const [apiResponse, setapiResponse] = useState();
  const [transactionStart, setTransactionStart] = useState();
  const [transactionEnd, setTransactionEnd] = useState();
  const [flPrevPage, setflPrevPage] = useState(0);
  const captchaRef = useRef(null)

  useEffect(() => {
    console.log("v2.7 CusNum Revert LIVE")
    setRecaptchaCheck(false);
  },[]);

  const getLastThreeMonths = (dash) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 12);
  
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Add 1 as months are zero-indexed
    const day = currentDate.getDate();
  
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    if (dash){
      const newDateString = `${year}-${formattedMonth}-${formattedDay}`;
      return newDateString;
    } else {
      const newDateString = `${year}${formattedMonth}${formattedDay}`;
      return newDateString;
    }
  };
  
  const handleBlur = (e, l) => {
    if(e.target.value.length >= l){
      e.target.classList.remove("errorInput");
    } else {
      e.target.classList.add("errorInput");
    }
  };

  const cleanInput = (value) => {
   let newInput = value.replaceAll(' ', '');
   let newInput2 = newInput.replaceAll('/' , '');
   return newInput2;
  }
  
  const formatDate = (date) => {
    let year = date.slice(0,4);
    let month = date.slice(4,6);
    let day = date.slice(6,8);
    return `${year}-${month}-${day}`
  }

  const closeBalance = () => {
    setShowBalance(false);
    setLoading(false);
    setShowErr(false)
    setapiResponse();
  }

  const verify = () => {
    captchaRef.current.getResponse().then(res => {
        setCaptchaToken(res);
        setRecaptchaCheck(true);
    })
  };

  const postCard = async (cardNumber, expiryDate, cvv2 )=> {
    setLoading(true);
    if (cardNumber && expiryDate && cvv2 !== '') {
      setShowErr(false);
      const newCardNo = cleanInput(cardNumber);
      const newExpNo = cleanInput(expiryDate);
      try {
        const result = await httpRequest.post(`/transactions?token=${captchaToken}`, { 
            cardNumber:newCardNo,
            expiryDate:newExpNo,
            cvv2,
            startDate:getLastThreeMonths(false),
            endDate: new Date(new Date().getTime() + (12 * 60 * 60 * 1000)).toISOString().slice(0, 10).replaceAll("-", "")
          }
        );
        let res = result.data;
        captchaRef.current.reset();
        if(res.outcomeCode === '0'){
          setLoading(false);
          setShowBalance(true);
          setapiResponse(res);
          setTransactionStart(getLastThreeMonths(true));
          setTransactionEnd(new Date().toISOString().slice(0, 10));
          captchaRef.current.reset();
        } else {
          setShowErr(true);
          setErrorMsg("Incorrect Card Details or Card does not exist. Please try again");
          setLoading(false);
          captchaRef.current.reset();
        }
      } 
      catch (err) {
        setLoading(false);
        setShowErr(true);
        setErrorMsg("Incorrect Card Details or Card does not exist. Please try again");
        captchaRef.current.reset();
      }
    } else {
      setShowErr(true);
      setErrorMsg('Please fill out all fields')
      setLoading(false);
      captchaRef.current.reset();
    }
  };

  const submitDateTransaction = async (
    cardNumber, 
    expiryDate, 
    cvv2, 
    startDate, 
    endDate, 
    flNextPage, 
    prevpage,
    lastTransNo,
    lastTransSysDate,
    lastTransSysTime
    ) => {
    setLoading(true);
    if (cardNumber && expiryDate && cvv2 !== '') {
      if(prevpage === null || prevpage === undefined){
        setflPrevPage(0);
      } else if (prevpage === true) {
        setflPrevPage(prevpage++);
      } else {
        setflPrevPage(prevpage--);
      }
      setShowErr(false);
      const newCardNo = cleanInput(cardNumber);
      const newExpNo = cleanInput(expiryDate);
      try {
        const result = await httpRequest.post(`/transactions`, { 
            cardNumber:newCardNo,
            expiryDate:newExpNo,
            cvv2,
            startDate,
            endDate,
            flNextPage,
            lastTransNo,
            lastTransSysDate,
            lastTransSysTime
          }
        );
        let res = result.data;
        if(res.outcomeCode === '0'){
          setLoading(false);
          setapiResponse(res);
          setTransactionStart(formatDate(startDate));
          setTransactionEnd(formatDate(endDate));
        } else {
          setShowErr(true);
          setErrorMsg("Invalid Date Range, please try again");
          setLoading(false);
        }
      } 
      catch (err) {
        setLoading(false);
        setShowErr(true);
        setErrorMsg("Invalid Date Range, please try again");
      }
    } else {
      setShowErr(true);
      setErrorMsg("Invalid Date Range, please try again");
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <LandingPage />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {showBalance ?'':<h3>Check my balance</h3> }
        {showBalance ? 
        <>  
        <div className='balance-container'>
          <div className='balance-wrapper'>
            <p >Your current balance</p>
            <h1><sup>$</sup>{apiResponse.accountBalance}</h1>
          </div>
        </div>
        <div className='transaction-wrapper'>
          <div className='transaction-bg'>
            <div className='transaction-head'>
              <h3>Transactions</h3>
              {/* <div className='transaction-date-container'>
                <input type="date" value={transactionStart} onChange={(e)=>{setTransactionStart(e.target.value)}}  />
                <span>-</span>
                <input type="date" value={transactionEnd} onChange={(e)=>{setTransactionEnd(e.target.value)}} />
                <img src={SearchIcon} alt="Search Transaction Date" onClick={()=>submitDateTransaction(cardNumber,exp,cvv2, transactionStart.replaceAll('-', ''), transactionEnd.replaceAll('-', ''))} />
              </div> */}
            </div>
            <div className='transaction-sup'>
              <p className='two'>Please note only your last 10 transactions within the last 12 months can be displayed. If you require earlier transaction history, please email payze_support@tranxactor.com</p>
            </div>
            {/* Transaction List */}
            { isLoading ? <div className="loader-container"><div className='loader'></div></div>  : 
              apiResponse && apiResponse.transactions.length !== 0 ? apiResponse.transactions.map((item, index)=> {
              return <TransactionList key={index} shopName={item.shopName} desc={item.transDesc} amount={item.amount} currency={item.currency} date={item.sysDate} />
            })
            :
            <p className='transactions-none'>No Transactions Found</p>
            }
            { isLoading ? '' : flPrevPage !== 0 ? 
              <button className='transaction-next' 
              onClick={()=>submitDateTransaction(
                cardNumber,exp,cvv2, 
                transactionStart.replaceAll('-', ''), transactionEnd.replaceAll('-', ''), 
                flPrevPage - 1, false,
                apiResponse.transactions[apiResponse.transactions.length-1].transNo,
                apiResponse.transactions[apiResponse.transactions.length-1].sysDate,
                apiResponse.transactions[apiResponse.transactions.length-1].sysTime,
                )}
                >
                <span className='green'>←</span>Prev</button>
              :
              ''
            }
            {/* Navigation Buttons */}
            {/* { isLoading ? '' : apiResponse.flNextPage !== 0 ? 
              <button className='transaction-next' 
              onClick={()=>submitDateTransaction(
                cardNumber,exp,cvv2, 
                transactionStart.replaceAll('-', ''), transactionEnd.replaceAll('-', ''), 
                apiResponse.flNextPage, true, 
                apiResponse.transactions[apiResponse.transactions.length-1].transNo,
                apiResponse.transactions[apiResponse.transactions.length-1].sysDate,
                apiResponse.transactions[apiResponse.transactions.length-1].sysTime,
                )}
                >Next<span className='green'>→</span></button>
              :
              ''
            } */}
          </div>
          <button className="lime-button" onClick={()=>closeBalance()} >Close</button>
        </div>
        </>
        : 
        <form className='App-form' onSubmit={(e)=>e.preventDefault()}>
          {showErr ?  
          <div className='error-message'>
            <p>{errorMsg}</p>
          </div>: ''
          }

          {/* <div className='info-container'>
            <p className='info-instruc'>Enter your Customer Number located on the back of your card.</p>
            <p
              className='info-icon'
            >
              ℹ
            </p>
            <div className='info-tooltip'>
              <img src='/images/eza.png' alt='Customer Number location' />
              You can find your customer number located at the <strong>bottom right</strong> on the <strong>back of your card.</strong>
            </div>
          </div> */}

          <label htmlFor="cardnumber">Card Number</label>
          <MaskedInput 
            id="cardnumber" 
            name="payze-cardnumber" 
            placeholder={"XXXX XXXX XXXX XXXX"}
            onBlur={(e)=>handleBlur(e,19)}
            type="text" 
            guide={false}
            onChange={(e) => {setCardNumber(e.target.value)}}
            value={cardNumber}
            mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,' ',/[0-9]?/, /[0-9]?/,/[0-9]?/ ]}
            />
          <div className='two-columns'>
            <div className='input-container' style={{width:"48%"}}>
              <label htmlFor="exp" name="payze-exp">Expiry Date</label>
              <MaskedInput id="exp" type="text" 
              mask={[/[0-1]/, /[0-9]/, '/', /[2-4]/, /[0-9]/]}
              guide={false}
              onBlur={(e)=>handleBlur(e,5)}
              placeholder={"MM/YY"}
              onChange={(e) => {setExp(e.target.value)}}
              value={exp}
              />
            </div>
            <div className='input-container' style={{width:"48%"}}>
              <label htmlFor="pin" name="payze-cvv">CVV</label>
              <MaskedInput id="cvv" 
              mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
              guide={false}
              type="text" 
              onBlur={(e)=>handleBlur(e,3)}
              placeholder={"XXX"}
              onChange={(e) => {setCvv2(e.target.value)}}
              value={cvv2}
              />
            </div>
          </div>
          <Reaptcha
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={captchaRef}
            theme={'dark'}
            onVerify={verify}
          />
          <button className={`lime-button ${isLoading || !recaptchaCheck ? `disabled` : ''}`} 
            disabled={isLoading || !recaptchaCheck} 
            onClick={()=>postCard(cardNumber,exp,cvv2)}>{
            isLoading ? 
            <div className='loader'></div> : 
            "Get Card Balance"}
          </button>
        </form>
        }
      </header>
      
    </div>
  );
}

export default App;
